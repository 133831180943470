import React from 'react';
import './SuccessPage.css'; // Assume you have a CSS file for styling

function SuccessPage() {
  return (
    <div className="success-page">
      <h1>Merci pour votre achat !</h1>
      <p>Votre commande a été traitée avec succès. Vous recevrez bientôt un email de confirmation avec les détails de votre commande et les informations de suivi.</p>
      <div className="actions">
        <button onClick={() => window.location.href = '/'}>Continuer vos achats</button>
        <button onClick={() => window.location.href = '/orders'}>Suivre ma commande</button>
      </div>
    </div>
  );
}

export default SuccessPage;