import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Home from './pages/Home';
import ProductList from './pages/ProductList';
import ProductDetail from './pages/ProductDetail';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import './App.css'; // Global styles
import Contact from './pages/Contact';
import SuccessPage from './pages/SuccessPage';
import FailurePage from './pages/FailurePage';
import { CartProvider } from './context/cartContext';
import Formation from './pages/Formation';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './pages/ScrollToTop';
import AboutPage from './pages/About';
 // Import the ScrollToTop component

function App() {
  return (
    <CartProvider>
      <Router>
      <ScrollToTop/>
        <Header />
        
        <main>
          <Routes>
          
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/product/:productId" element={<ProductDetail/>} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success" element={<SuccessPage/>} />
            <Route path="/cancel" element={<FailurePage/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/formation" element={<Formation/>} />
            <Route path="/about" element={<AboutPage/>} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
      </Router>
    </CartProvider>
  );
}

export default App;

