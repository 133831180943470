import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/cartContext'; // Import useCart from your context
import './Header.css'; // Ensure you create a Header.css file with styles
import { ReactComponent as CartIcon } from '../assets/cart.svg';
import { ReactComponent as ToothLogo } from '../assets/tooth.svg'; // Make sure you have a cart SVG icon in your assets folder

const Header = () => {
  const { state: cartItems } = useCart();

  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  return (
    <header className="site-header">
      <div className="header-wrapper">
        <Link to="/" className="logo">
        <span className="logo-text">Dentella</span>
          
        </Link>
        <nav className="navigation">
        <Link to="/" className="nav-link">Accueil</Link>
          <Link to="/products" className="nav-link">Materiel de Blanchiment dentaire</Link>
          <Link to="/Formation" className="nav-link">Formations</Link>
          <Link to="/contact" className="nav-link contact-link">Contact
          </Link>
          <div className="cart-link-wrapper">
            <Link to="/cart" className="nav-link cart-link">
              
              <CartIcon className="cart-icon" />
              {cartItemCount > 0 && (
                <span className="cart-item-count">{cartItemCount}</span>
              )}
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;


