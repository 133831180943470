import React from 'react';
import './Footer.css'; // Make sure to update the Footer.css with the new styles
import { ReactComponent as InstagramIcon } from '../assets/instagram.svg'; // Add Instagram SVG icon to your assets
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp.svg'; // Add WhatsApp SVG icon to your assets
import { Link } from 'react-router-dom';


const Footer = () => {
  // Replace with your actual Instagram and WhatsApp URLs
  const instagramUrl = 'https://www.instagram.com/fournisseurblanchiment.d/';
  const whatsappUrl = 'https://wa.me/yourwhatsappnumber';
  const ibameoUrl = 'https://ibameo.tech/';

  return (
    <footer className="site-footer">
      <div className="footer-wrapper">
        <div className="social-links">
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="social-icon" />
          </a>
          <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon className="social-icon" />
          </a>
        </div>
        <div className="copyright">
          © 2024 Dentella. All rights reserved.
        </div>
        <div className="designed-by">
          Designed by <a href={ibameoUrl} target="_blank" rel="noopener noreferrer">Ibameo</a>.
        </div>
        <Link className="about" to="/about">À propos</Link>
      </div>
    </footer>
  );
};

export default Footer;


//MyECommerce j aime bien le nom 