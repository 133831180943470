import React, { createContext, useReducer, useContext } from 'react';
import { INCREASE_QUANTITY, DECREASE_QUANTITY, REMOVE_FROM_CART } from './cartActions';





// Define the context
const CartContext = createContext();

// Actions
const ADD_TO_CART = 'ADD_TO_CART';

// Reducer to handle actions
function cartReducer(state, action) {
  switch (action.type) {
    case ADD_TO_CART:
      // Add to cart logic
      const newProduct = action.payload;
      const exists = state.find((item) => item.id === newProduct.id);

      if (exists) {
        // Increase quantity
        return state.map((item) =>
          item.id === newProduct.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        // Add new item
        return [...state, { ...newProduct, quantity: 1 }];
      }
    
      case INCREASE_QUANTITY:
        return state.map((item) =>
          item.id === action.payload ? { ...item, quantity: item.quantity + 1 } : item
        );
      case DECREASE_QUANTITY:
        return state.map((item) =>
          item.id === action.payload ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item
        );
      case REMOVE_FROM_CART:
        return state.filter((item) => item.id !== action.payload);
      // ... other actions
      default:
        return state;
  }
  
}

// Provider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, []);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook to use the cart context
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const addToCart = (product) => {
  return {
    type: ADD_TO_CART,
    payload: product,
  };
};
export const removeFromCart = (productId) => ({
    type: 'REMOVE_FROM_CART',
    payload: productId,
  });
  
  export const increaseQuantity = (productId) => ({
    type: 'INCREASE_QUANTITY',
    payload: productId,
  });
  
  export const decreaseQuantity = (productId) => ({
    type: 'DECREASE_QUANTITY',
    payload: productId,
  });
