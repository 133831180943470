import React from 'react';
import './FailurePage.css'; // Assume you have a CSS file for styling

function FailurePage() {
  return (
    <div className="failure-page">
      <h1>Échec du paiement</h1>
      <p>Nous sommes désolés, mais nous n'avons pas pu traiter votre paiement. Cela peut être dû à un problème technique ou à des informations de paiement incorrectes.</p>
      <div className="suggestions">
        <p>Veuillez vérifier vos informations de paiement et essayer à nouveau, ou utilisez un autre moyen de paiement. Si le problème persiste, n'hésitez pas à contacter notre service clientèle pour obtenir de l'aide.</p>
        <div className="actionsF">
          <button onClick={() => window.location.href = '/product'}>Réessayer le paiement</button>
          <button onClick={() => window.location.href = '/contact'}>Contacter le support</button>
        </div>
      </div>
    </div>
  );
}

export default FailurePage;