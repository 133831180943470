import React, { useState } from 'react';
import { useCart } from '../context/cartContext'; // Adjust the path as needed
import './Checkout.css'; // Your CSS file for styling


import { loadStripe } from '@stripe/stripe-js';



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Replace 'your_stripe_public_key_here' with your Stripe public key
const stripePromise = loadStripe('pk_test_51P2I66Rx6ZYx3anhv6HVdIQWdOmiIPkb6AP0z2zG7GwTUSCNjH8yYjQ6tUMjMOvptmlN2YYi43m7Ejb3bkK1Debm00DMfLhozV');


const Checkout = () => {
  const { state: cartItems } = useCart();
  const [address, setAddress] = useState({
    fullName: '',
    mobileNo: '',
    flat: '',
    area: '',
    city: '',
    pinCode: '',
  });


  const [isLoading, setIsLoading] = useState(false);

  

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };



 

  const fillDummyValues = () => {
    setAddress({
      fullName: 'John Doe',
      mobileNo: '1234567890',
      flat: '123',
      area: 'Main Street',
      city: 'Metropolis',
      pinCode: '123456',
    });
  };


  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log('Processing checkout with address:', address);
    // Additional form processing logic here
  };

  const handlePlaceOrderClick = async () => {
    setIsLoading(true); // Start loading
  
    try {
      const stripe = await stripePromise;
      
      const response = await fetch('https://ssu-sand.vercel.app/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items: cartItems, address: address }),
      });
      
      const session = await response.json();
  
      if (response.ok) {
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
  
        if (result.error) {
          console.error(result.error.message);
          alert(result.error.message); // Display the error to the user
        }
      } else {
        console.error('Network response was not ok.', session);
        alert('There was a problem with your transaction. Please try again.'); // Provide user feedback
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred. Please try again.'); // Provide user feedback
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };
  

  return (
    <div className="checkout">
      <div className="address-section">
        <h2>Votre adresse de livraison</h2>
        <form onSubmit={handleFormSubmit}>
          <input name="fullName" value={address.fullName} onChange={handleInputChange} placeholder="Nom Complet " type="text" />
          <input name="mobileNo" value={address.mobileNo} onChange={handleInputChange} placeholder="Numero de telephone " type="text" />

          <input name="area" value={address.area} onChange={handleInputChange} placeholder="Adresse Complet" type="text" />
          <input name="city" value={address.city} onChange={handleInputChange} placeholder="Ville" type="text" />
          <input name="pinCode" value={address.pinCode} onChange={handleInputChange} placeholder="code Postale" type="text" />
          <div className="form-buttons">
          </div>
        </form>
      </div>
      <div className="order-summary-section">
      <h2>Votre Commande:</h2>
        {cartItems.map((item) => {
          // Move the require inside the map function
          const imageSrc = require(`../assets/products/${item.images[0]}`);
          return (
            <div key={item.id} className="order-item">
              <img src={imageSrc} alt={item.name} className="order-item-image" />
              <div className="order-item-details">
                <span className="order-item-name">{item.name}</span>
                <span className="order-item-quantity">x{item.quantity}</span>
                <span className="order-item-price">€{item.price.toFixed(2)}</span>
                {item.reduction && <span className="order-item-original-price">{item.originalPrice.toFixed(2)}</span>}
              </div>
            </div>
          );
        })}
        <div className="order-summary-total">
          <div className="summary-row">
            <span>Total Products</span>
            <span>{cartItems.length}</span>
          </div>
          <div className="summary-row">
            <span>Sous total</span>
            <span>€{calculateTotal()}</span>
          </div>
          {/* Include other summary details like discount, delivery charges */}
          <div className="summary-row total">
            <span>Total TTC</span>
            <span>€{calculateTotal()}</span> {/* This total will be calculated including any discounts and additional charges */}
          </div>
          <button className="place-order-button" onClick={handlePlaceOrderClick}>Commander et Payer</button>
          {isLoading && <div className="loading-spinner"></div>}
        </div>
      </div>
    </div>
  );
};

export default Checkout