import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import products from './product.json'; // Make sure this path is correct
import './ProductList.css'; // Your CSS file for styling

const ProductList = () => {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    // Here you would fetch the data from your product.json file
    // For now, we're just loading it directly from the imported JSON
    setProductList(products);
  }, []);

  return (
    <div className="product-list">
      {productList.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
