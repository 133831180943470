import React, { useState } from 'react';
import './Contact.css'; // Ensure your styles are defined in Contact.css
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp.svg'; // Ensure you have a WhatsApp SVG icon

const Contact = () => {
  const whatsappNumber = "+33776343334";
  const [contactReason, setContactReason] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const contactReasonMessages = {
    'appointment': 'Prise de RDV pour séance de blanchiment',
    'claim': 'Réclamations',
    'order': 'Commande',
    'other': 'Autre'
  };

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(`Bonjour, je souhaite vous contacter concernant: ${contactReasonMessages[contactReason] || 'une demande générale'}.`)}`;

  return (
    <div className="contact-container">
      <h1>Contactez Nous</h1>
      <p>Si vous avez des questions ou besoins spécifiques, n'hésitez pas à nous contacter.</p>

      <select value={contactReason} onChange={e => setContactReason(e.target.value)} className="contact-reason">
        <option value="">-- Choisissez une raison --</option>
        <option value="appointment">Prise de RDV pour séance de blanchiment</option>
        <option value="claim">Réclamations</option>
        <option value="order">Commande</option>
        <option value="other">Autre</option>
      </select>

      {contactReason === 'appointment' && (
        <input
          type="date"
          value={appointmentDate}
          onChange={e => setAppointmentDate(e.target.value)}
          className="appointment-date-picker"
        />
      )}

      <div className="contact-methods">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-contact">
          <WhatsAppIcon className="whatsapp-icon" />
          <span>Chattez avec nous sur WhatsApp</span>
        </a>
      </div>
    </div>
  );
};

export default Contact;
