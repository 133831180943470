import React from 'react';
import { useCart } from '../context/cartContext';
import { useNavigate } from 'react-router-dom';
import { increaseQuantity, decreaseQuantity, removeFromCart } from '../context/cartActions'; // Adjust the path as needed
import './Cart.css';


const Cart = () => {
  const { state: cartItems, dispatch } = useCart();

  const handleIncreaseQuantity = (productId) => {
    dispatch(increaseQuantity(productId));
  };

  const handleDecreaseQuantity = (productId) => {
    dispatch(decreaseQuantity(productId));
  };

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart(productId));
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };


  const navigate = useNavigate(); // This replaces the 'useHistory' hook
  
  const proceedToCheckout = () => {
    navigate('/checkout'); // Use 'navigate' instead of 'history.push'
  };

  //const imageSrc = require(`../assets/products/${item.images[0]}`);


  return (
    <div className="cart">
      <div className="cart-summary">
        <h2>Panier ({cartItems.length})</h2>
        {cartItems.map((item) => {
const imageSrc = require(`../assets/products/${item.images[0]}`);
return (
          <div className="cart-item" key={item.id}>
            <img src={imageSrc} alt={item.name} className="cart-item-image" />
            <div className="cart-item-details">
              <h4>{item.name}</h4>
              <p className="cart-item-price">
                {item.reduction ? <span className="cart-item-original-price">{item.originalPrice}</span> : null}
                <span className="cart-item-final-price">{item.price}€</span>
              </p>
              <div className="cart-item-quantity">QTY
              <button onClick={() => handleDecreaseQuantity(item.id)}>-</button>
              <span>{item.quantity}</span>
              <button onClick={() => handleIncreaseQuantity(item.id)}>+</button>
            </div>
            <button onClick={() => handleRemoveFromCart(item.id)} className="cart-item-remove">
            ❌SUPP
            </button>
          </div>
          </div>
          );
        })}
      </div>
      <div>
        <h2>Votre Panier Total :</h2>
       
        <div className="cart-total">
          <p>Total</p>
          <p>{calculateTotal()}€</p>
        </div>
        <button className="checkout-button" onClick={proceedToCheckout}>
       Checkout
    </button>
      </div>
    </div>
  );
};


export default Cart;


/* {cartItems.map((item) => (
          <div key={item.id}>
            <p>{item.name} ({item.quantity} item)</p>
             <p>{item.price}€</p>
            </div>
        ))}*/

