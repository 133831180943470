import React from 'react';
import './About.css'; // Import the CSS file here

function AboutPage() {
  return (
    <div className="about-container">
      <h1>Livraison</h1>
      <p>Delivery information and policies.</p>

      <h1>Politique de remboursement</h1>
      <p>Refund policies and conditions.</p>

      <h1>Conditions Générales Des Ventes</h1>
      <p>General sales conditions.</p>

      <h1>Politique De Confidentialité</h1>
      <p>Privacy policy details.</p>

      <h1>Conditions d'utilisation</h1>
      <p>Terms of use for your services.</p>
    </div>
  );
}

export default AboutPage;