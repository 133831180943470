import React from 'react';
import './ProductCard.css'; // Your CSS file for styling
import { useCart, addToCart } from '../context/cartContext';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../assets/right-arrow.svg'; // Ensure you have an arrow icon SVG
import { ReactComponent as CartIcon } from '../assets/cart.svg'; // Make sure you have a cart SVG icon in your assets folder
import { toast } from 'react-toastify';



const ProductCard = ({ product }) => {
  const { dispatch } = useCart();

  const handleAddToCartClick = () => {
    dispatch(addToCart(product));
    toast.success(`${product.name} ajouté au panier!`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); // Show success toast
  };




  const navigate = useNavigate();

const handleClick = () => {
  navigate(`/product/${product.id}`); // This should match your routing setup
};

const imageSrc = require(`../assets/products/${product.images[0]}`);

  return (
    <div className="product-card" >
      
      <img src={imageSrc}  alt={product.name} className="product-image"  onClick={handleClick} />
      <div className="product-info" >
        <ArrowIcon className="product-arrow" onClick={handleClick} />
        <h3 className="product-name">{product.name}</h3>
        <p className="product-description">{product.description}</p>
        <div className="product-bottom" >
        <div className="product-pricing">
          {product.reduction ? (
            <span className="product-original-price">{product.originalPrice.toFixed(2)}€</span>
          ) : null}
          <span className="product-price">{product.price.toFixed(2)}€</span>
        </div>
        <div onClick={handleAddToCartClick} className="add-to-cart-buttoncard">
       Ajouter au panier
    </div>
       
      </div>
      </div>
    </div>
    
  );
};

export default ProductCard;
