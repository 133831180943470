import React from 'react';
import Banner from './Banner';
import './Home.css'; // Make sure to create a Home.css file for the styles

const Home = () => {
  return (
    <div className="home">
      <Banner />

      
      {/* You can add more sections/components here as needed */}
    </div>
  );
};

export default Home;
