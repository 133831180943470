import React from 'react';
import { Link } from 'react-router-dom';
import img2 from '../assets/slide/sl02.jpg';

import './Banner.css'; // Assuming your CSS is correctly set up

const Banner = () => {
  

  return (
    <div classname="background">
      
    <div className="banner">
    <div class="twinkle-star star-1"></div>
  <div class="twinkle-star star-2"></div>
  <div class="twinkle-star star-3"></div>
  <div class="twinkle-star star-4"></div>
  <div class="twinkle-star star-5"></div>
  <div class="twinkle-star star-6"></div>
      <div className="banner-content">
        <h1 className="banner-title">Illuminez Votre Sourire avec Dentella</h1>
        <p className="banner-subtitle">
        Découvrez des produits professionnels pour le blanchiment dentaire.
        </p>
        <div className="banner-buttons">
          
          <Link to="/products" className="banner-button shop-now">Nos Produits</Link>
          <Link to="/about" className="banner-button learn-more">Explorer</Link>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;



