import React from 'react';
import './Formation.css'; // Make sure to create a Formation.css file for styles
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp.svg'; // Add WhatsApp SVG icon to your assets

const Formation = () => {
  // Replace with your actual WhatsApp URL
  const whatsappUrl = 'https://wa.me/yourwhatsappnumber';
  const trainingDescription = "Devenez un professionnel du blanchiment dentaire avec notre formation complète. Apprenez les techniques les plus avancées et sécuritaires du marché. Nous vous fournissons les connaissances et compétences nécessaires pour exceller dans cette spécialité en demande. Pour plus d'informations, contactez-nous sur WhatsApp.";

  return (
    <div className="formation-container">
      <h1>Formation Professionnelle en Blanchiment Dentaire</h1>
      <p className="description">{trainingDescription}</p>
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
        <WhatsAppIcon className="whatsapp-icon" />
        Demandez plus d'infos
      </a>
    </div>
  );
};

export default Formation;