import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCart,addToCart} from '../context/cartContext';
import productsData from './product.json';
import './ProductDetail.css';
import { toast } from 'react-toastify';


const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const { productId } = useParams();
  const { dispatch } = useCart(); // make sure your context provides a dispatch function

  useEffect(() => {
    const productData = productsData.find(p => p.id.toString() === productId);
    setProduct(productData);
  }, [productId]);

  if (!product) {
    return <div>Loading...</div>;
  }

  const handleAddToCartClick = () => {
    // Make sure the action type and payload match what your reducer expects
    dispatch({ type: 'ADD_TO_CART', payload: { ...product, quantity: 1 } });
    toast.success(`${product.name} ajouté au panier!`, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); // Show success toast
  };

  const imageSrc = require(`../assets/products/${product.images[0]}`);

  return (
    <div className="product-detail-container">
      <div className="product-detail-image-wrapper">
      <img src={imageSrc}  alt={product.name} className="product-detail-image" />
      </div>
      <div className="product-detail-info-wrapper">
        <h1 className="product-detail-name">{product.name}</h1>
        <p className="product-detail-description">{product.longDescription}</p>

        <div className="product-detail-meta">
          <div className="product-price">
            {product.reduction && (
              <span className="original-price">€{product.originalPrice}</span>
            )}
            <span className="final-price">€{product.price}</span>
          </div>
         
          <div className="product-tags">
            {product.tags.map(tag => (
              <span key={tag} className="product-tag">{tag}</span>
            ))}
          </div>
        </div>

        <button onClick={handleAddToCartClick} className="add-to-cart-button">
          Ajouter au panier
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;


/*<div className="product-quantity-discounts">
{product.quantityDiscount && product.quantityDiscount.map(discount => (
  <div key={discount.threshold} className="quantity-discount">
    Achetez {discount.threshold} ou plus pour {discount.discountPercent}% de reduction!
  </div>
))}
</div>*/
